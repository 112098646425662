export default {
  demografi: {
    nama_pasien: {
      widget: 'wtext',
      data: null,
      col: 5,
      label: 'Nama Pasien'
    },
    no_rekam_medis: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'No. Rekam Medis'
    },
    tanggal_lahir: {
      widget: 'wdatenormal',
      data: null,
      col: 4,
      label: 'Tanggal Lahir'
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 3
    },
    umur: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Umur'
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'No.Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No.Reg',
      col: 3
    },
    kartu_anggota: {
      widget: 'wtext',
      data: null,
      label: 'No.Kartu',
      col: 4
    },
    jenis_kelamin: {
      widget: 'wradio',
      label: 'Jenis Kelamin',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 5
    }
  },
  inputan_dokter: {
    // tanggal: {
    //   label: 'Tanggal',
    //   widget: 'wdatenormal',
    //   data: null,
    //   col: 6
    // },
    // jam: {
    //   label: 'Jam',
    //   widget: 'wtime',
    //   data: null,
    //   col: 6,
    //   property: { attrs: { suffix: 'WIB', type: 'number' } }
    // },
    kepada: {
      label: 'Kepada Yth. TS (Dokter Konsulen)',
      widget: 'wautocomplatesingle',
      data: [],
      col: 12
    },
    diagnosis: {
      label: 'Diagnosis Kerja',
      widget: 'wtextarea',
      data: null,
      col: 12
    },
    riwayat: {
      label:
        'Riwayat Klinik Singkat : (Riwayat Klinik Singkat Termasuk Laboratorium dan Radiologi)',
      widget: 'wtextarea',
      data: null,
      col: 12
    },
    konsultasi: {
      label: 'Konsultasi Yang Diminta',
      widget: 'wradio',
      data: [
        { text: 'Rawat Bersama', value: 'Rawat Bersama' },
        { text: 'Alih Rawat', value: 'Alih Rawat' },
        { text: 'Konsul', value: 'Konsul' }
      ],
      col: 12
    }
    // jawaban: {
    //   label: 'Jawaban Konsultasi : (Temuan, Diagnosis, dan Saran)',
    //   widget: 'wtextarea',
    //   data: null,
    //   col: 12
    // }
  }
  // input_soap: {
  //   s_: {
  //     s_: {
  //       label: 'S (SOAP)',
  //       widget: 'wtextarea',
  //       data: null,
  //       col: 12
  //     }
  //   },
  //   a_: {
  //     a_: {
  //       label: 'A (SOAP)',
  //       widget: 'wtextarea',
  //       data: null,
  //       col: 12
  //     }
  //   },
  //   p_: {
  //     p_: {
  //       label: 'P (SOAP)',
  //       widget: 'wtextarea',
  //       data: null,
  //       col: 12
  //     }
  //   },
  //   o_emt: {
  //     emt_1: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'EMT 1',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     emt_2: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'EMT 2',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     emt_3: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'EMT 3',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     emt_4: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'EMT 4',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     ket_emt: {
  //       widget: 'wtextarea',
  //       data: null,
  //       label: 'Keterangan EMT',
  //       col: 12
  //       // property: { attrs: { suffix: '/ mmHg' } }
  //     }
  //   },
  //   o_rom: {
  //     rom_1: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'ROM 1',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     rom_2: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'ROM 2',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     rom_3: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'ROM 3',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     rom_4: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'ROM 4',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     ket_rom: {
  //       widget: 'wtextarea',
  //       data: null,
  //       label: 'Keterangan ROM',
  //       col: 12
  //       // property: { attrs: { suffix: '/ mmHg' } }
  //     }
  //   }
  // }
}
